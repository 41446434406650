import React from 'react'
import { graphql } from 'gatsby'
import Meta from '../components/meta'
import get from 'lodash/get'
import Layout from '../components/layout'
import Section from '../components/section'

class AboutPage extends React.Component {
  render() {
    const sections = get(this, 'props.data.contentfulPage.sections', [])
    const page = get(this.props, 'data.contentfulPage')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const defaultMeta = get(this.props, 'data.site.siteMetadata')

    const metaTitle = page.metaTitle || page.headline || siteTitle
    const metaDescription = page.metaDescription || defaultMeta.description

    const schema = {
      "@context":"https://schema.org",
      "@type": "Organization",
      name: 'Defund to Refund',
      email: 'Info@defund2refund.org',
      description: page.metaDescription,
      url: 'https://defund2refund.org',
    }
    
    return (
      <Layout location={this.props.location}>
        <Meta
          defaultMeta={defaultMeta}
          title={metaTitle}
          description={metaDescription}
          jsonLD={schema}
        />
        <div>
          {sections.map((section) => {
            return <Section key={section.id} {...section} />
          })}
        </div>
      </Layout>
    )
  }
}

export default AboutPage

export const pageQuery = graphql`
  query AboutPageQuery {
    site {
      siteMetadata {
        title
        description
        siteUrl
        image
        socialInstagram
      }
    }
    contentfulPage(slug: {eq: "about"}) {
      headline
      metaTitle
      metaDescription
      sections {
        id
        heading
        theme
        variant
        ctaLabel
        ctaLink {
          __typename
          ... on ContentfulPage {
            slug
            rootLink
            sys {
              type
              contentType {
                sys {
                  contentful_id
                }
              }
            }
          }
        }
        body {
          json
        }
      }
    }
  }
`

